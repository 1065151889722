import { messageMap } from '../message/message'

export const getResMessage = function(data: any): Array<any> {
    const messageList = [];
    for (let i = 0; i < data.messageList.length; i++) {
        const messageObj = data.messageList[i];
        const message = getMessage(messageObj.messageCode, messageObj.messageParam);
        messageList[i] = {
            "message": message
        };
    }

    return messageList;
}

export const getMessage = function(messageCode: string, messageParam: string[]): string {
    let message = messageMap.get(messageCode);
    if (message == undefined) {
        message = ""
    }
 
    for (let j = 1; j < messageParam.length + 1; j++) {
        message = message.replace("%" + j, messageParam[j - 1]);
    }

    return message;
}