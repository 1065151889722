import { createStore } from 'vuex';

const options = {
  state() {
    return {
      loading: false,
      isAlert: false,
      authority: 'admin',
      userId: '',
      headerInfo: {
        userId: '',
        functionId: '',
      },
      userSearchInfo: {
        userName: '',
        funcitonIds: [],
      },
      loginUserData: {
        userId: '',
        userNm: '',
        departmentNm: '',
        telNum: '',
        userKbnCd: '',
        companyCd: '',
        companyNm: '',
        preOfficeCd: '',
        preDepositorCd: '',
        preOfficeNmKj: '',
        preDepositorNm: '',
        mailAdd: '',
        useStopDt: '',
        functionId: [],
        mailAddYsd: [],
        mailAddYsdConfirm: [],
        userInfoSearchOfficeDepositorMasterDtos: [],
      },
    }
  },
  mutations: {},
  actions: {},
  getters: {},
  modules: {},
  
};

export const store = createStore({
  ...options
})

export function resetStore() {
  store.replaceState(createStore(options).state)
}

export default store