import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import elementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css'; // import element-plus css style
import jaLocale from 'element-plus/lib/locale/lang/ja';
import './assets/css/comStyle.scss';
import '@fontsource-variable/m-plus-2';

jaLocale.el.pagination = {
  goto: '',
  pagesize: '',
  total: '',
  pageClassifier: '',
};

router.beforeEach((to, from, next) => {
  // Deal with the following operations before route jumps.
  const validPaths = ['Login', 'UC016S02', 'UC002S01', 'UC002S02'];
  // 画面タイトルを変更
  if (typeof to.meta.title === 'string') {
    document.title = to.meta.title;
  }

  if (validPaths.includes(to.name as string)) {
    // If go to the login view
    next(); // Let it pass
  } else {
    // If not
    if (sessionStorage.getItem('userId')) {
      // Justify the browser session, whether have stored the login information.
      next(); // If it have stored the user information ,go to the view you wanna go.
    } else {
      // Else
      next({ path: '/login' }); // Go to the login  view
    }
  }
});

// ストアの復帰 / 保管処理
if (sessionStorage.getItem('store')) {
  store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStorage.getItem('store'))))
  console.log('The store data has been restored from the sessionStorage')
}

window.addEventListener('beforeunload', () => {
  sessionStorage.setItem('store', JSON.stringify(store.state));
});

createApp(App)
  .use(store)
  .use(router)
  .use(elementPlus, {
    locale: jaLocale,
  })
  .mount('#app');
