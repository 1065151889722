import { messageFormat } from '@/message/message';

/* eslint-disable */
export function required(item) {
  return {
    required: true,
    message: messageFormat('MSGW002', item),
    trigger: 'blur',
  };
}
export function email() {
  return {
    type: 'email',
    message: messageFormat('MSGW012', 'メールアドレス'),
    trigger: 'blur',
  };
}
export function itemMaxLen(item, max = 256) {
  return {
    max,
    message: messageFormat('MSGW004', item, max),
    trigger: 'blur',
  };
}
export function itemRangeLen(item, min = 8, max = 12) {
  let length = min + '-' + max;
  return {
    max,
    min,
    message: messageFormat('MSGW008', item, length),
    trigger: 'blur',
  };
}
export function AlphaNum(item) {
  return {
    pattern: /^[a-z0-9A-Z]+$/,
    message: messageFormat('MSGW009', item, '英数字'),
    trigger: 'blur',
  };
}

export function PassAlphaNumMark(item) {
  return {
    pattern: /^[a-zA-Z0-9※#!"$%$&'()*+,-./:;<=>?@[¥\]^_{|}¯]+$/,
    message: messageFormat('MSGW009', item, '英数字記号'),
    trigger: 'blur',
  };
}

export function PassValidation(item) {
  return {
    pattern: /^(?=.*[A-Z])(?=.*[\d])(?=.*[^a-zA-Z0-9])/,
    message: messageFormat(
      'MSGW010',
      item,
      '大文字1文字以上、数字1文字以上、記号1文字以上',
    ),
    trigger: 'blur',
  };
}
