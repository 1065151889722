import { httpPost, httpDownload } from '@/utils/HttpUtil';
import { AxiosPromise } from 'axios';

/**
* ユーザーIDとパスワードより、システムをログインする。
*/
export const login = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/Login', data);
};

/**
* 新パスワードより、システムログインしたユーザーIDのパスワードを変更する。
*/
export const passwordChange = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/PasswordChange', data);
};

/**
* 入力された商品コードより、商品情報を検索する。
*/
export const pickingGoodsSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uGoodsMaster/PickingGoodsSearch', data);
};

/**
* 画面入力した出荷指示内容を登録し、その結果を返却する。
*/
export const shippingOrderRegister = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uShippingOrderInfo/ShippingOrderRegister', data);
};

/**
* 入力された郵便番号から住所を取得して、提供する。

*/
export const addressSet = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uPostalcodeMaster/AddressSet', data);
};

/**
* 入力された検索条件より、入庫予定情報を検索して、一覧で表示する。
*/
export const storeOrderInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uStoreOrderInfo/StoreOrderInfoSearch', data);
};

/**
* 画面表示している入庫予定情報一覧データより、CSVファイルを作成し、ダウンロードする。
*/
export const storeOrderDownLoad = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uStoreOrderInfo/StoreOrderDownLoad', data);
};

/**
* 入力された検索条件より、入出庫履歴情報を検索して、一覧で表示する。
*/
export const historyInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uHistoryInfo/HistoryInfoSearch', data);
};

/**
* 入力された検索条件より、入出庫履歴情報を検索して、バッチ処理を行う。
*/
export const historyInfoDownLoadBatch = (data: FormData):AxiosPromise<any> => {
  return httpDownload('/uHistoryInfo/HistoryInfoDownLoadBatch', data);
};

/**
* 入力された検索条件より、ユーザー情報を検索し、一覧で表示する。
*/
export const userInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserInfoSearch', data);
};

/**
* ユーザーIDより、ユーザー情報を削除する。
*/
export const userInfoDelete = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserInfoDelete', data);
};

/**
* 入力されたユーザー情報より、ユーザー情報を更新する。
*/
export const userDetailInfoUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserDetailInfoUpdate', data);
};

/**
* 入力されたユーザー情報より、ユーザー情報を新規し、登録する。
*/
export const userInfoRegister = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserInfoRegister', data);
};

/**
* 選択されたファイルのデータをアップロードして、DBに登録する。
*/
export const storeOrderInfoUpload = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uStoreOrderInfo/StoreOrderInfoUpload', data);
};

export const storeOrderInfoUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uStoreOrderInfo/StoreOrderInfoUpdate', data);
};

/**
* ストレージから、入庫予定一括アップロード項目定義ファイルを削除する。
*/
export const storeOrderInfoUploadFormatDelete = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uStoreOrderInfo/StoreOrderInfoUploadFormatDelete', data);
};

/**
* アップロード項目定義はストレージ上のファイルに保存する。
*/
export const storeOrderInfoUploadFormatSave = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUploadDataFormat/StoreOrderInfoUploadFormatSave', data);
};

/**
* ユーザー詳細画面の初期表示で、ユーザーIDより、ユーザー詳細情報を取得する。
*/
export const userDetailPageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserDetailPageInit', data);
};

/**
* 設定ファイルの内容を編集し、ストレージ上のファイルに保存する。
*/
export const setFileSave = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSetFile/SetFileSave', data);
};

/**
* 代表ユーザーIDの利用停止申請を安田倉庫担当者のメールアドレスへ送信する。
*/
export const userStopRequest = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserStopRequest', data);
};

/**
* 安田倉庫担当者は荷主のユーザーIDの申請・変更・利用停止申請を確認して、承認者のメールアドレスへ送信する。
*/
export const userRequestConfirm = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserRequestConfirm', data);
};

/**
* 安田倉庫管理者は荷主のユーザーIDの利用停止申請を承認する。
*/
export const userStopRequestResister = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserStopRequestResister', data);
};

/**
* 代表ユーザーID申請を荷主のメールアドレスへ送信する。
*/
export const userIdRequestSend = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserIdRequestSend', data);
};

/**
* 荷主は管理者ID申請利用規約書をダウンロードする。
*/
export const pdfDownLoad = (data: FormData):AxiosPromise<any> => {
  return httpDownload('/uPdfDownLoad/PdfDownLoad', data);
};

/**
* 荷主はユーザー情報を入力して、代表ユーザーID申請を安田倉庫担当者のメールアドレスへメール送信する。
*/
export const userIdRequest = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserIdRequest', data);
};

/**
* 安田倉庫管理者は荷主の代表ユーザーID申請を承認する。
*/
export const userIdResisterSend = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserIdResisterSend', data);
};

/**
* 在庫商品情報を検索して、画面に一覧で表示する。
*/
export const stockInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uStockMaster/StockInfoSearch', data);
};

/**
* 検索された在庫情報より、データをCSVファイルに出力して、ダウンロードする。
*/
export const stockInfoDownLoad = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uStockMaster/StockInfoDownLoad', data);
};

/**
* 入力された検索条件より、出荷指示情報を検索して、画面に一覧で表示する。
*/
export const shippingInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uShippingOrderInfo/ShippingInfoSearch', data);
};

/**
* 画面表示している出荷指示情報一覧データより、CSVファイルを作成し、ダウンロードする。
*/
export const shippingInfoDownLoad = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uShippingOrderInfo/ShippingInfoDownLoad', data);
};

/**
* 入庫予定一括アップロード画面を初期表示する。
*/
export const storeOrderInfoUploadPageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUploadDataFormat/StoreOrderInfoUploadPageInit', data);
};

/**
* 出荷指示一括アップロード画面を初期表示する。
*/
export const shippingUploadPageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUploadDataFormat/ShippingUploadPageInit', data);
};

/**
* 出荷指示一括アップロード設定画面を初期表示する。
*/
export const shippingUploadSetPageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUploadDataFormat/ShippingUploadSetPageInit', data);
};

/**
* 選択されたファイルのデータをアップロードして、DBに登録する。
*/
export const shippingUpload = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uShippingOrderInfo/ShippingUpload', data);
};

/**
* ストレージから、出荷指示一括アップロード項目定義ファイルを削除する。
*/
export const shippingUploadFormatDelete = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUploadDataFormat/ShippingUploadFormatDelete', data);
};

/**
* アップロード項目定義をストレージ上のファイルに保存する。
*/
export const shippingUploadFormatSave = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUploadDataFormat/ShippingUploadFormatSave', data);
};

/**
* 代表ユーザー変更情報を送信する。
*/
export const managerUserUpdateSend = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/ManagerUserUpdateSend', data);
};

/**
* 代表ユーザー変更情報を承認する。
*/
export const managerUserUpdateApprove = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/ManagerUserUpdateApprove', data);
};

/**
* 登録済みのユーザー情報（管理者、一般）の検索と照会を行う。
*/
export const userMasterInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserMasterInfoSearch', data);
};

/**
* 検索されたユーザー情報（管理者、一般）より、データをCSVファイルに出力して、ダウンロードする。
*/
export const userMasterInfoDownLoad = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserMasterInfoDownLoad', data);
};

/**
* 入力された商品コードより、商品明細情報を検索する。
*/
export const goodsDetail1Search = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uStockMaster/GoodsDetail1Search', data);
};

/**
* 入力された商品コードより、商品詳細情報を検索する。
*/
export const goodsDetail2Search = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uStockMaster/GoodsDetail2Search', data);
};

/**
* ログインユーザーIDより、営業所と寄託者リストを取得する。
*/
export const officeDepositorInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/OfficeDepositorInfoSearch', data);
};

/**
* ログインユーザーのメールアドレスにパスワードリセットリクエストを送信する。
*/
export const passwordResetSend = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/PasswordResetSend', data);
};

/**
* ログイン画面で入力されたユーザーIDより、メールアドレスを検索する。
*/
export const mailAddressSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/MailAddressSearch', data);
};

/**
* 入庫予定一括アップロード設定画面を初期表示する。
*/
export const storeOrderInfoUploadSetPageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUploadDataFormat/StoreOrderInfoUploadSetPageInit', data);
};

/**
* 配送状況照会画面を初期表示する。
*/
export const sendinfInfoSearchPageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSendingInfo/SendinfInfoSearchPageInit', data);
};

/**
* 入力された検索条件より、配送状況照会情報を検索して、一覧で表示する。
*/
export const sendingInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSendingInfo/SendingInfoSearch', data);
};

/**
* 画面表示している配送状況照会情報一覧データより、CSVファイルを作成して、ダウンロードする。
*/
export const sendingInfoDownLoad = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSendingInfo/SendingInfoDownLoad', data);
};

/**
* 設定ファイル画面を初期表示する。
*/
export const setFilePageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSetFile/SetFilePageInit', data);
};

/**
* パスワードリセット（メール送信）画面を初期表示する。
*/
export const passwordResetSendPageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/PasswordResetSendPageInit', data);
};

/**
* 入力された検索条件より、入出庫履歴情報を検索して、バッチ処理を行う。
*/
export const historyInfoDownLoad = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uHistoryInfo/HistoryInfoDownLoad', data);
};

/**
* 設定ファイルの内容を取得する。
*/
export const setFilePageTxt = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSetFile/SetFilePageTxtGet', data);
};

/**
* 利用規約の内容を取得する。
*/
export const ruleTxtGet = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSetFile/RuleTxtGet', data);
};

/**
* メール中身のURLからセッションデータ保持IDより、セッションデータを取得する。
*/
export const sessionIDGet = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSession/SessionIDGet', data);
};

/**
* 画面選択された営業所と寄託者より、前回営業所コードと前回寄託者コードを更新する。
*/
export const depositorInfoDecide = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/DepositorInfoDecide', data);
};

/**
* 注意事項とお知らせ事項内容を取得する。
*/
export const infoTxtGet = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSetFile/infoTxtGet', data);
};

/**
* 検索条件より、全ての寄託者ユーザーを一覧で取得する。ユーザー区分より、代表ユーザーが一段階目で、管理ユーザーと一般ユーザーは二段階で設定する。
*/
export const depositorInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/DepositorInfoSearch', data);
};

/**
* 画面で選択されたユーザーIDより、営業所と寄託者の詳細情報を取得し、画面に表示する。
*/
export const depositorUserInfoPageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/DepositorUserInfoPageInit', data);
};

/**
* 画面入力された営業所、寄託者と安田担当者メールアドレス情報を変更する。
*/
export const depositorInfoUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/DepositorInfoUpdate', data);
};

/**
* 入力されたユーザー情報をDBに更新して変更する。
*/
export const managerUserUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/ManagerUserUpdate', data);
};

/**
* システムをログアウトする。
*/
export const logout = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/Logout', data);
};

/**
* 運用マニュアルをPDF出力する。
*/
export const helpPDFShow = (data: FormData):AxiosPromise<any> => {
  return httpDownload('/uHelper/HelpPDFShow', data);
};

/**
* 入力されたユーザー情報より、ユーザー利用可能機能情報を更新する。
*/
export const userFunctionInfoUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserFunctionInfoUpdate', data);
};

