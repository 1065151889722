import { AxiosResponse } from 'axios';
import Encoding from 'encoding-japanese';

/**
 * Public Methods for download using
 */
export const addDownloadOper = (res: AxiosResponse<any>, fileName: string, blobType: string):any => {
    const blob = getBlob(res, blobType);

    const aTag = document.createElement("a");
    aTag.download = fileName;
    document.body.appendChild(aTag);
    aTag.href = URL.createObjectURL(blob);
    aTag.click();
    URL.createObjectURL(blob);
    document.body.removeChild(aTag);
};

export const addDownloadNewWindow = (res: AxiosResponse<any>, fileName: string, blobType: string):any => {
  
  const blob = getBlob(res, blobType);
  const aTag = document.createElement("a");
  aTag.download = fileName;
  document.body.appendChild(aTag);
  aTag.href = URL.createObjectURL(blob);
  window.open(aTag.href);
};

export const getBlob = (res: AxiosResponse<any>, blobType: string):Blob => {
  let blob: Blob;
  if (blobType === "excel") {
    blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
  } else if (blobType === "pdf") {
    blob = new Blob([res.data], { type: "application/pdf" });
  } else {
    const unicodeList = Encoding.stringToCode(res.data);
    const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'UNICODE');
    const shiftJisString = new Uint8Array(shiftJisCodeList);
    blob = new Blob([shiftJisString], { type: "application/octet-stream; charset=sjis" });
  }
  return blob;
}