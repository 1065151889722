<template>
  <el-container class="parent-wrapper">
    <component :is="mainHeader"></component>
    <el-container class="main-frame">
      <el-aside class="custom-aside">
        <component :is="naviMenu"></component>
      </el-aside>
      <div class="main-container">
        <router-view v-slot="{ Component }">
          <transition name="fade-transform" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </el-container>
  </el-container>
</template>

<script type="ts" setup>
import{ shallowRef }from"vue"
import NaviMenu from '@/components/layout/NaviMenu';
import MainHeader from '@/components/layout/MainHeader.vue';

const naviMenu = shallowRef(NaviMenu)
const mainHeader = shallowRef(MainHeader)
</script>

<style scoped>
.parent-wrapper {
  width: 100vw;
  height: 100vh;
}
.main-frame {
  position: fixed;
  top: 4rem;
  height: calc(100vh - 4rem);
}
.main-container {
  overflow-y: hidden;
  overflow-x: auto;
  width: calc(100vw - 320px);
  /* min-width: 960px; */
  top: 0;
  background-color: #e1e1e1;
  position: relative;
}
</style>
