import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import MainBoard from '../components/MainBoard.vue';

//ログイン
import Login from '../views/UC/login/UC014S02.vue';

// トップメニュー
const UC014S01 = () => import('@/views/UC/top-menu/UC014S01.vue');

// ユーザー管理
const UC011S01 = () => import('@/views/UC/user-management/UC011S01.vue');
const UC002S01 = () => import('@/views/UC/user-management/UC002S01.vue');
const UC002S02 = () => import('@/views/UC/user-management/UC002S02.vue');
const UC005S01 = () => import('@/views/UC/user-management/UC005S01.vue');
const UC005S02 = () => import('@/views/UC/user-management/UC005S02.vue');
const UC008S01 = () => import('@/views/UC/user-management/UC008S01.vue');
const UC013S01 = () => import('@/views/UC/user-management/UC013S01.vue');

// 入庫予定登録/照会
const UC019S01 = () => import('@/views/UC/store/UC019S01.vue');
const UC019S02 = () => import('@/views/UC/store/UC019S02.vue');

//入庫予定情報照会
const UC020S01 = () => import('@/views/UC/store/UC020S01.vue');

// 出荷指示登録/照会
const UC021S01 = () => import('@/views/UC/ship/UC021S01.vue');
const UC022S01 = () => import('@/views/UC/ship/UC022S01.vue');
const UC022S02 = () => import('@/views/UC/ship/UC022S02.vue');
const UC023S01 = () => import('@/views/UC/ship/UC023S01.vue');

// 出荷指示登録/照会
const UC024S01 = () => import('@/views/UC/information/UC024S01.vue');
const UC025S01 = () => import('@/views/UC/information/UC025S01.vue');
const UC026S01 = () => import('@/views/UC/information/UC026S01.vue');

//パスワード設定
const UC016S02 = () => import('@/views/UC/password-reset/UC016S02.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/top-menu',
    component: MainBoard,
    redirect: '/top-menu/UC014S01',
    children: [
      {
        path: '/top-menu/UC014S01',
        name: 'UC014S01',
        meta: { title: 'トップメニュー' },
        component: UC014S01,
      },
      
    ]
  },
{
    path: '/user-management/UC002S01',
    name: 'UC002S01',
    meta: { title: '利用規約' },
    component: UC002S01,
  },
  {
    path: '/user-management/UC002S02',
    name: 'UC002S02',
    meta: { title: 'ユーザーID登録申請' },
    component: UC002S02,
  },
  {
    path: '/user-management',
    component: MainBoard,
    redirect: '/user-management/UC013S01',
    children: [
      {
        path: '/user-management/UC013S01',
        name: 'UC013S01',
        meta: { title: 'ユーザー一覧' },
        component: UC013S01,
      },
      {
        path: '/user-management/UC005S01',
        name: 'UC005S01',
        meta: { title: '代表ユーザー情報変更' },
        component: UC005S01,
      },
      {
        path: '/user-management/UC005S02',
        name: 'UC005S02',
        meta: { title: '代表ユーザー変更申請' },
        component: UC005S02,
      },
      {
        path: '/user-management/UC008S01',
        name: 'UC008S01',
        meta: { title: 'YOURSWeb利用停止申請' },
        component: UC008S01,
      },
      {
        path: '/user-management/UC011S01',
        name: 'UC011S01',
        meta: { title: 'ユーザー詳細' },
        component: UC011S01,
      }
    ]
  },
  {
    path: '/store',
    component: MainBoard,
    redirect: '/store',
    children: [
      {
        path: '/store/UC019S01',
        name: 'UC019S01',
        meta: { title: '入庫予定一括アップロード' },
        component: UC019S01,
      },
      {
        path: '/store/UC019S02',
        name: 'UC019S02',
        meta: { title: '入庫予定一括アップロード設定' },
        component: UC019S02,
      },
      {
        path: '/store/UC020S01',
        name: 'UC020S01',
        meta: { title: '入庫予定情報照会' },
        component: UC020S01,
      },
    ],
  },
  {
    path: '/ship',
    component: MainBoard,
    redirect: '/ship/UC021S01',
    children: [
      {
        path: '/ship/UC021S01',
        name: 'UC021S01',
        meta: { title: '出荷指示登録' },
        component: UC021S01,
      },
      {
        path: '/ship/UC022S01',
        name: 'UC022S01',
        meta: { title: '出荷指示一括アップロード' },
        component: UC022S01,
      },
      {
        path: '/ship/UC022S02',
        name: 'UC022S02',
        meta: { title: '出荷指示一括アップロード設定' },
        component: UC022S02,
      },
      {
        path: '/ship/UC023S01',
        name: 'UC023S01',
        meta: { title: '出荷指示情報照会' },
        component: UC023S01,
      },
    ],
  },
  {
    path: '/information',
    component: MainBoard,
    redirect: '/information/UC025S01',
    children: [
      {
        path: '/information/UC024S01',
        name: 'UC024S01',
        meta: { title: '在庫照会' },
        component: UC024S01,
      },
      {
        path: '/information/UC025S01',
        name: 'UC025S01',
        meta: { title: '入出庫履歴照会' },
        component: UC025S01,
      },
      {
        path: '/information/UC026S01',
        name: 'UC026S01',
        meta: { title: '配送状況照会' },
        component: UC026S01,
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: 'ログイン' },
    component: Login,
    // redirect: '/login/UC014S02',
  },
  {
    path: '/password-reset/UC016S02',
    name: 'UC016S02',
    meta: { title: 'パスワード設定' },
    component: UC016S02,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

export default router;
