import axios, { AxiosPromise } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import store from '../store/index';
import { ElMessage } from 'element-plus';
import { messageFormat } from '@/message/message';
import { getResMessage } from '../utils/MessageUtil';
import router from '../router/index';

/**
 * Creates an axios instance
 */
const http = axios.create({
  baseURL: process.env.VUE_APP_BFF_URL,
  headers: { 'Content-Type': 'multipart/form-data' },
});

/**
 * Interceptor for request
 */
http.interceptors.request.use(
  (config: any) => {
    if (store.state.headerInfo.functionId === "YW019S01_EVT999") {
      store.state.loading = false;
    } else {
      store.state.loading = true;
    }
    config.headers = {
      'X-Trace-Id': uuidv4(),
      'X-User-Id': store.state.headerInfo.userId,
      'X-Function-Id': store.state.headerInfo.functionId,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/**
 * Interceptor for response
 * TODO: Add error handle here
 */
http.interceptors.response.use(
  (response) => {
    return successState(response);
  },
  (error) => {
    errorState(error);
    return Promise.reject(error);
  },
);

/**
 * Export base http post
 * @param {String} url
 * @param {FormData} data
 * @param {String} postType
 */
export const httpPost = (url: string, data: FormData): AxiosPromise<any> => {
  return http({ method: 'post', data, url });
};

/**
 * Export base http post
 * @param {String} url
 * @param {FormData} data
 * @param {String} postType
 */
export const httpDownload = (
  url: string,
  data: FormData,
): AxiosPromise<any> => {
  return http({ method: 'post', responseType: 'blob', data, url });
};

/**
 * Error handle
 * TODO:  Template Need to make a complete error handle strategy(the function here is not right)
 * @param {any} errRes
 */
function errorState(errRes: any) {
  store.state.loading = false;
  if (errRes.response) {
    switch (errRes.response.status) {
      case 400: {
        const messageList = getResMessage(errRes.response.data);

        if (messageList.length > 0) {
          let errMessage = '';
          messageList.forEach((item) => {
            errMessage += item.message + '\n';
          });

          ElMessage({
            showClose: true,
            duration: 0,
            message: errMessage,
            type: 'error',
          });
        }
        break;
      }

      case 401:
        router.push({ name: 'Login', params: { errorFlg: '401' } });

        break;
      case 403:
        ElMessage({
          showClose: true,
          duration: 0,
          message: messageFormat('MSGW040'),
          type: 'error',
        });
        break;
      case 500:
        ElMessage({
          showClose: true,
          duration: 0,
          message: messageFormat('MSGW001'),
          type: 'error',
        });
        break;
    }
  }
}

/**
 * Handle response when state is success
 * @param {*} res
 */
function successState(res: any) {
  store.state.loading = false;
  return res;
}
